<template>
	<FsLayout>
		<component :is="activeComponent" :componentData="componentData" />
	</FsLayout>
</template>
<script lang="ts" setup>
import { defineAsyncComponent, h, provide, ref, computed } from "vue";
import { Tools, toolsMenu } from "./constants";
import type { ToolComponentBase } from "./types";
import FsLayout from "./Layout/index.vue";
definePageMeta({
	layout: "common"
});
const route = useRoute();
const websiteStore = useWebsiteStore();
const runtimeConfig = useRuntimeConfig();
const localeLink = useLocaleLink();
const { website } = storeToRefs(websiteStore);
// 如果当前id不属于tools中的任何一个id，则跳转到404页面
if (!Object.keys(Tools).includes(route.params.id as string)) {
	navigateTo(localeLink(runtimeConfig.public.VITE_NUXT_DOMAIN + "/404.html"), { external: true });
}
// 当前语言不是英文时，跳转到首页
if (!["en"].includes(website.value)) {
	const domain = runtimeConfig.public.VITE_NUXT_DOMAIN;
	const path = domain + localeLink("/", website.value as any);
	await navigateTo(path, { external: true });
}
const componentData = ref<ToolComponentBase>({
	ToolMenuData: toolsMenu
});
const modulesId = ref<keyof typeof Tools>(route.params.id as keyof typeof Tools);
const asyncComponent = (name: string) => {
	console.log(name, "name");

	if (name) {
		return defineAsyncComponent(() => import(`./pages/${name}/index.vue`));
	} else {
		// 跳转404
		navigateTo(localeLink(runtimeConfig.public.VITE_NUXT_DOMAIN + "/404.html"), { external: true });
	}
};
const activeComponent = computed(() => {
	return asyncComponent(Tools[modulesId.value]);
});
const breadcrumbData = computed(() => {
	const baseBreadcrumb = [
		{
			name: "Home",
			path: "/",
			active: false
		},
		{
			name: "Query Tools",
			path: "/tool/tool-home",
			active: modulesId.value === "tool-home"
		}
	];
	if (modulesId.value != "tool-home") {
		const currenName = toolsMenu.find(item => item.filterName === modulesId.value)?.label;
		baseBreadcrumb.push({
			name: currenName || "",
			path: `/tool/${modulesId.value}`,
			active: true
		});
	}
	return baseBreadcrumb;
});
provide("breadcrumbData", breadcrumbData);
provide("modulesId", modulesId);
</script>
