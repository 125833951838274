<template>
	<div class="fs-tool-header">
		<!-- <strong class="fs-banner-title" :style="`color: #ffffff;`">FS Tool</strong> -->
		<div class="fs-tool-header-content">
			<div class="fs-tool-header-content-breadcrumb">
				<CommonBreadcrumb :breadcrumbData="breadcrumbData" />
			</div>
			<div class="fs-tool-header-content-title">FS Tool</div>
			<div class="fs-tool-header-content-dec">
				<!-- Convenient access to product MIB information you need. -->
				Easy access to one-stop query tool platform
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { type WritableComputedRef } from "vue";
import CommonBreadcrumb from "@/pages/Tools/components/CommonBreadcrumb/index.vue";

// PC、Pad、Mobile
const bannerUrlPc = computed(() => `url('https://resource.fs.com/mall/generalImg/20240618112420u8bab9.jpg')`);
const breadcrumbData = inject("breadcrumbData") as WritableComputedRef<any>;

const bannerUrlPad = computed(() => `url('https://resource.fs.com/mall/generalImg/20240618112420ykx47x.jpg')`);
const bannerUrlMobile = computed(() => `url('https://resource.fs.com/mall/generalImg/20240618112420of76eh.jpg')`);
</script>
<style lang="scss" scoped>
@import url("./index.scss");
.fs-tool-header {
	@include pc() {
		background-image: v-bind(bannerUrlPc);
	}
	@include pad() {
		background-image: v-bind(bannerUrlPad);
	}
	@include mobile() {
		background-image: v-bind(bannerUrlMobile);
	}
	// &-content {
	// 	&-breadcrumb {
	// 		@include mobile {
	// 			padding-left: 16px;
	// 		}
	// 		@include pad {
	// 			padding-left: 24px;
	// 		}
	// 	}
	// }
}
</style>
