<template>
	<div v-if="breadcrumbData.length > 0" class="fs-tool-breadcrumb">
		<FsBreadcrumb separator="/">
			<FsBreadcrumbItem
				v-for="(item, index) in breadcrumbData"
				:key="index"
				:active="item.active"
				@breadcrumb-click="e => handleClick(e, item)"
				>{{ item.name }}</FsBreadcrumbItem
			>
		</FsBreadcrumb>
	</div>
</template>
<script lang="ts" setup>
import { FsBreadcrumb, FsBreadcrumbItem } from "fs-design";
import type { BreadcrumbProps } from "../../types";
const router = useRouter();
const props = defineProps<BreadcrumbProps>();
const handleClick = (e: MouseEvent, item: any) => {
	if (item.active) {
		return;
	}
	router.push(item.path);
};
</script>
<style lang="scss" scoped>
.fs-tool-breadcrumb {
	margin-bottom: 24px;
	:deep(.is-active) {
		color: $textColor1;
		cursor: default;
	}
	:deep(.fs-breadcrumb-item) {
		&:hover {
			color: $textColor1;
		}
	}
}
</style>
