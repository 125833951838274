<template>
	<div class="fs-tool-container">
		<FsToolBanner v-if="modulesId === 'tool-home'"></FsToolBanner>
		<div class="fs-tool-content">
			<div class="fs-tool-content-main">
				<CommonBreadcrumb v-if="modulesId != 'tool-home'" :breadcrumbData="breadcrumbData" />
				<slot></slot>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import type { WritableComputedRef } from "vue";
import CommonBreadcrumb from "../components/CommonBreadcrumb/index.vue";
import FsToolBanner from "./components/FsToolBanner/index.vue";
const router = useRouter();
const breadcrumbData = inject("breadcrumbData") as WritableComputedRef<any>;
const modulesId = inject("modulesId") as WritableComputedRef<any>;
const handleSelect = (e: any) => {
	console.log(e);
	if (e.key) {
		router.push(`/tool/${e.key}`);
	}
};
</script>
<style lang="scss" scoped>
@import url("./styles/index.scss");
</style>
<style lang="scss">
.fs-cascaderM__content {
	a {
		color: $textColor1;
	}
}
</style>
